<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">휴일관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
              :items="ASP_CUST_KEY"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
              class="form-inp sm"
              name="name" label="조회년도" outlined hide-details
              v-model="YEARS">
              <template v-slot:label>
                조회년도
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="HDY_GB_CD"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              placeholder="선택하세요"
              v-model="HDY_NEWGB_CD"
            >
              <template v-slot:label>
                휴일구분
              </template>
            </v-select>
          </div>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="selectHDYlist">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 휴일목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        휴일목록
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddHDY')" outlined class="btn-etc2" v-on:click="addHdy">휴일추가</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddSat')" outlined class="btn-etc2 ml-2" v-on:click="addSat">토요일추가</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddSun')" outlined class="btn-etc2 ml-2" v-on:click="addSun">일요일추가</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          height="222px"
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          show-select
          @page-count="pageCount = $event"
          @click:row="rowClick"
          v-model="SELECTHDY"
          no-data-text="검색된 휴일이 없습니다."
          fixed-header
          :item-class="isActiveRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 휴일이 검색되었습니다.
        </p>
      </div>
    </div>
    <!--// 휴일목록 -->
    <!-- 휴일상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        휴일상세정보
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-etc2" v-on:click="newBtn">신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="showAlertDel">삭제</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" v-on:click="showAlertReg">저장</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              휴일상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="" />
              <col width="127px" />
              <col width="" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">휴일일자</v-badge>
                </th>
                <td>
                 <v-menu
                    v-model="DT_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="HDY_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp full icon-calendar"
                        append-icon="svg-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="HDY_DT"
                      color="#F7AFAE"
                      no-title
                      @input="DT_menu = false"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">휴일명</v-badge>
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name" value="휴일명" id="id" outlined hide-details=""
                    v-model="HDY_NM">
                  </v-text-field>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">휴일구분</v-badge>
                </th>
                <td>
                  <div class="form-inp full">
                    <v-select
                      :items="HDY_DETAIL_GB_CD"
                      item-value="CD"
                      item-text="CD_NM"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      v-model="HDY_GB_NM"
                    >
                    </v-select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" rowspan="4">휴일설명</th>
                <td colspan="5">
                  <v-textarea
                  class="textarea-type0"
                  label=""
                  :rules="textareaRules"
                  :value="textareaValue"
                  no-resize
                  outlined
                  v-model="HDY_FULL_NM"
                ></v-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 휴일상세정보 -->
  </div>
</template>

<script>
import api from '@/store/apiUtil.js'
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_M810301",
  mixins: [mixin],
  data() {
    return {
      DT_menu: false,
      menu1: false,
      menu2: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      dropItems: [{ title: "option1" }],
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: false,
        },
        { text: '휴일일자', value: 'HOLIDAY_DT', align: 'center', sortable: false, },
        { text: '휴일명', value: 'HOLIDAY_NM',align: 'center', sortable: false, },
        { text: '휴일구분', value: 'HOLIDAY_GB_NM', align: 'center',sortable: false, },
      ],
      gridDataText: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      textareaRules: [v => v.length <= 200 || 'Max 200 characters'],
      textareaValue: '200자 제한!',

      ASP_CUST_KEY:this.$store.getters['userStore/GE_USER_ROLE'].company,

      HDY_GB_CD:[
        {
          CD: '',
          CD_NM: '전체'
        }
      ],

      HDY_DETAIL_GB_CD:[],

      HDY_ID:'',

      ASP_NEWCUST_KEY:'',
      YEARS:'',
      HDY_NEWGB_CD:'',

      HDY_DT:'',
      HDY_NM:'',
      HDY_FULL_NM:'',
      HDY_GB_NM:'',

      TALK_HOLIDAY_ID: '',

      SELECTHDY:[],

      SELHDYARR:[],
     }
  },

  created() {
  },

  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

    //조회버튼 클릭 이벤트
    selectHDYlist(){
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/list",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        SEARCH_YYYY: this.YEARS,
        SEARCH_HOLIDAY_GB_CD: this.HDY_NEWGB_CD,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;
        for(var i=0; i<this.gridDataText.length;i++){
          this.gridDataText[i]["index"]= i+1;
        }
        this.page=1;
        this.SELECTHDY=[];
        this.SELHDYARR=[];

        this.searchRow1();
      })
      .catch((err) => {
        alert(err);
      })
    },

    //휴일 상세정보
    //row click이벤트
    rowClick (item) {

      this.HDY_ID = item.TALK_HOLIDAY_ID;
      let DT = item.HOLIDAY_DT;
      let DT_Y = DT.substr(0,4);
      let DT_M = DT.substr(4,2);
      let DT_D = DT.substr(6,2);
      this.HDY_DT = DT_Y + "-" + DT_M + "-" + DT_D;
      //this.HDY_DT = item.HOLIDAY_DT;
      this.HDY_NM = item.HOLIDAY_NM;
      this.HDY_GB_NM = item.HOLIDAY_GB_CD;
      this.HDY_FULL_NM = item.HOLIDAY_FULL_NM;

      this.TALK_HOLIDAY_ID = item.TALK_HOLIDAY_ID;

      this.SELHDYARR[this.SELHDYARR.length] = item.TALK_HOLIDAY_ID
    },

    isActiveRow(item){
      const activeClass = item.TALK_HOLIDAY_ID === this.HDY_ID ? 'active' : '';
      return activeClass;
    },

    //휴일추가 버튼클릭 이벤트
    addHdy(){
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사구분을 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/hldy/regist",
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '1',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
        if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
          this.common_alert('정상처리 되었습니다.', 'done')
        } else {
          this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
        }
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //토요일추가 버튼클릭 이벤트
    addSat(){
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사구분을 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/wkend/regist",
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '3',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
         if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
          this.common_alert('정상처리 되었습니다.', 'done')
        } else {
          this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
        }       
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //일요일추가 버튼클릭 이벤트
    addSun(){
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사구분을 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/wkend/regist",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '4',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
        if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
          this.common_alert('정상처리 되었습니다.', 'done')
        } else {
          this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
        }          
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },

    //신규버튼 클릭 이벤트
    newBtn(){
      this.HDY_ID = '';
      var today = new Date();
      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var todat_DT = year + '-' + month  + '-' + day;

      this.HDY_DT = todat_DT;
      this.HDY_NM = '휴일명';
      this.HDY_FULL_NM = '휴일설명';
      this.HDY_GB_NM = '1';
    },

    //삭제버튼 클릭 이벤트
    deleteBtn(){
      for(let i in this.SELHDYARR){
        api.post("api/setting/holiday/manage/delete",   //api url입력
        {
          ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          TALK_HOLIDAY_ID: this.SELHDYARR[i],
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "setting.holiday.manage",
          "METHOD" : "delete",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.success();
        })
        .catch((err) => {
          this.fail();
        })
      }
    },

    afterDel(){
      this.HDY_DT = '';
      this.HDY_NM = '';
      this.HDY_FULL_NM = '';
      this.HDY_GB_NM = '';
      this.SELECTHDY=[];
      this.SELHDYARR=[];
    },

    //저장버튼 클릭 이벤트
    regBtn(){
      console.log("HDY_ID" + this.HDY_ID);
      if (this.HDY_DT == "" || this.HDY_DT == undefined) {
        this.showAlert("휴일 일자를 입력해주세요.");
        return;
      }
      if (this.HDY_NM == "" || this.HDY_NM == undefined) {
        this.showAlert("휴일명을 입력해주세요.");
        return;
      }
      if (this.HDY_GB_NM == "" || this.HDY_GB_NM == undefined) {
        this.showAlert("휴일구분을 선택해주세요.");
        return;
      }

      api.post("api/setting/holiday/manage/regist",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        HOLIDAY_DT: this.HDY_DT,
        HOLIDAY_NM: this.HDY_NM,
        HOLIDAY_GB_CD: this.HDY_GB_NM,
        HOLIDAY_DESC: this.HDY_FULL_NM,
        TALK_HOLIDAY_ID: 'HOL',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectHDYlist();
        this.HDY_DT = '';
        this.HDY_NM = '';
        this.HDY_FULL_NM = '';
        this.HDY_GB_NM = '';
        this.SELECTHDY=[];
        this.SELHDYARR=[];
        this.success();
      })
      .catch((err) => {
        this.fail();
      })

    },

    modiBtn(){
      console.log("HDY_ID" + this.HDY_ID);
      if (this.HDY_DT == "" || this.HDY_DT == undefined) {
        this.showAlert("휴일 일자를 입력해주세요.");
        return;
      }
      if (this.HDY_NM == "" || this.HDY_NM == undefined) {
        this.showAlert("휴일명을 입력해주세요.");
        return;
      }
      if (this.HDY_GB_NM == "" || this.HDY_GB_NM == undefined) {
        this.showAlert("휴일구분을 선택해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/modify",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        TALK_HOLIDAY_ID: this.HDY_ID,
        HOLIDAY_DT: this.HDY_DT,
        HOLIDAY_NM: this.HDY_NM,
        HOLIDAY_GB_CD: this.HDY_GB_NM,
        HOLIDAY_DESC: this.HDY_FULL_NM,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "modify",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectHDYlist();

        this.HDY_DT = '';
        this.HDY_NM = '';
        this.HDY_FULL_NM = '';
        this.HDY_GB_NM = '';
        this.SELECTHDY=[];
        this.SELHDYARR=[];

        this.success();
      })
      .catch((err) => {
        this.fail()
      })
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showAlertReg(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '휴일을 저장하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYes1,
        callNo: this.callNo1,
      })
    },

    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      if (this.HDY_ID == '' || this.HDY_ID == undefined){
        this.regBtn();
      } else {
        this.modiBtn();
      }
    },

    callNo1(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    showAlertDel(){
      if(this.SELHDYARR.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '휴일을 삭제하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes2,
        callNo: this.callNo2,
      })
    },

    callYes2() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.deleteBtn();
      this.afterDel();
      this.selectHDYlist();
    },

    callNo2(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },

    fail(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
        iconClass: 'svg-error-lg',
        type: 'default'
      })
    },

    searchRow1(){
      this.rowClick(this.gridDataText[0]);
    },

  },

  async mounted(){
    let codeName = ["TALK010"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.HDY_GB_CD = [...this.HDY_GB_CD, ...common_code[0].group_value];
    this.HDY_DETAIL_GB_CD = common_code[0].group_value;
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style>

</style>
